<template>
    <div class="container">
        <div id="echarts-google-map">
        </div>

        <input class="search-input-form" type="text" @input="searchChange" @focus="searchChange"
            @blur="showFilteredAssetName = false" v-model="searchText" name="search-text" placeholder="Search">

        <div class="select-box">
            <el-select :no-data-text="'No Data'" popper-class="map-custom-select" :style="{ width: '134px' }"
                v-model="selectedSite" filterable placeholder="" @visible-change="updateCenter">
                <el-option v-for="item in sites" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>

        <img v-if="allAssetsWithNoGeo.length" src="../assets/Map-Tab/fixed-map-mark.png" alt=""
            class="top-right-fixed-img" ref="draggableImage" draggable="false" @dragstart="onDragStart"
            @mousedown="onMouseDown">

        <!-- 无设备可添加 -->
        <el-tooltip v-else content="No device to add." placement="bottom">
            <img src="../assets/Map-Tab/fixed-map-mark.png" alt="" class="top-right-fixed-img" ref="draggableImage"
                draggable="false">
        </el-tooltip>

        <MapConfigure
            :style="{ position: 'absolute', top: MapConfigureTop < 0 ? 0 : MapConfigureTop + 'px', left: MapConfigureLeft < 0 ? 0 : MapConfigureLeft + 'px' }"
            :MapConfigureStaticData="MapConfigureStaticData" v-show="showMapConfigure" :isPlaying="showMapConfigure" :key="Date()"
            @delete="deleteCurrentAsset">
        </MapConfigure>

        <RadioSelectAssetName
            :style="{ position: 'absolute', top: RadioSelectAssetNameTop + 'px', left: RadioSelectAssetNameLeft + 'px' }"
            :allAssetsWithNoGeo="allAssetsWithNoGeo" v-show="showRadioSelectAssetName" :key="Date() + 1"
            @select="onSelectAssetName">
        </RadioSelectAssetName>

        <FilteredAssetName v-show="showFilteredAssetName" :filteredAssetList="filteredAssetList" @notify="updateSearch">
        </FilteredAssetName>

        <img v-show="showDragEndImage" src="../assets/Map-Tab/map-mark.png" alt="" class="drag-end-img"
            ref="dragEndImage">
    </div>
</template>

<script>

import MapConfigure from './map/MapConfigure.vue'; // 设备信息
import RadioSelectAssetName from './map/RadioSelectAssetName.vue'; // 无地理信息设备列表
import FilteredAssetName from './map/FilteredAssetName.vue'; // 搜索过滤后的无地理信息设备列表

import { getAllAssetVo, setAssetAttr, deleteAssetAttr, getDevicesDataByAssetIds } from "../api.js";
import LoadScriptsMixin from '@/mixins/LoadScriptMixin';
export default {
    components: {
        MapConfigure,
        RadioSelectAssetName,
        FilteredAssetName,
    },
    mixins: [LoadScriptsMixin],
    name: 'Map',
    props: {
        sites: {
            type: Array,
            default: () => [{
                value: 'China',
                label: 'China'
            }, {
                value: 'Hong Kong SAR',
                label: 'Hong Kong SAR'
            }, {
                value: 'Singapore',
                label: 'Singapore'
            },]
        },
    },
    data() {
        return {
            selectedSite: 'China', // 选中内容
            searchText: '', // 搜索文本
            MapConfigureTop: 0,
            MapConfigureLeft: 0,
            RadioSelectAssetNameTop: 0,
            RadioSelectAssetNameLeft: 0,
            showMapConfigure: false, // 默认不显示
            showRadioSelectAssetName: false, // 默认不显示
            showDragEndImage: false, // 默认不显示
            showFilteredAssetName: false, // 默认不显示
            filteredAssetList: [], // 搜索过滤后, 无地理信息的设备列表
            allAssetsWithGeo: [], // 有地理信息的设备列表
            allAssetsWithNoGeo: [], // 无地理信息的设备列表
            // MapConfigureAssetId: -1, // 当前设备 id
            MapConfigureStaticData: {}, // 当前设备静态数据

            strAssetIds: '',
            deviceKey_to_DeviceData_Map: {},
            // getDeviceDataIntervalId: null,

            markedDataInMap: [], // ECharts 图上标记数据
            EChartsInstance: null, // ECharts 实例
            // 经度(longitude) 纬度 (latitude)
            longitudeAndLatitudeMap: {
                'China': [116.405285, 39.904989], // http://www.jsons.cn/lngcodeinfo/0844F38DEC46A106
                'Hong Kong SAR': [114.137, 22.381], // https://wiki.openstreetmap.org/wiki/Hong_Kong
                'Singapore': [103.830391822121, 1.340863], // https://www.jingweizuobiao.com/coordinates/704384
            },
            previousMapMarkDataIndex: -1, // 地图标记上一次被点击的索引
        };
    },
    async created() {
    },
    methods: {
        /** 图上像素点坐标转换为谷歌地图上的经度和纬度
         * 
         * @param {number} x 像素点横坐标
         * @param {number} y 像素点纵坐标
         * @return {Array<number>} [ longitude, latitude ]
         */
        PixelToLngLat(x, y) {
            // 获取谷歌地图实例
            const map = this.EChartsInstance.getModel().getComponent("gmap").getGoogleMap();
            // const scale = Math.pow(2, GlobalMap.getZoom());
            // 参考: https://stackoverflow.com/questions/3723083/how-to-use-frompointtolatlng-on-google-maps-v3
            // http://jsfiddle.net/mhaq865o/5/
            const scale = 1 << map.getZoom(); // https://developers.google.com/maps/documentation/javascript/reference/map#Map.getZoom
            const projection = map.getProjection(); // https://developers.google.com/maps/documentation/javascript/reference/map#Map.getProjection
            // https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
            const NorthEast = map.getBounds().getNorthEast();
            const SouthWest = map.getBounds().getSouthWest();
            const topRight = projection.fromLatLngToPoint(NorthEast);
            const bottomLeft = projection.fromLatLngToPoint(SouthWest);

            const newLatLng = projection.fromPointToLatLng(new google.maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y));
            // console.log('当前经度和纬度坐标', newLatLng.lng(), newLatLng.lat());
            return [newLatLng.lng(), newLatLng.lat()];
        },
        /** 谷歌地图经纬度转换为图上像素点坐标
         * 
         *  参考: https://blog.csdn.net/y396397735/article/details/80466140
         * @param {Array<number>} arr [ longitude, latitude ]
         * @return {object} { x: number, y: number }
         */
        LngLatToPixel(arr) {
            const LatLngLiteral = { lat: arr[1], lng: arr[0] }; // https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngLiteral
            const map = this.EChartsInstance.getModel().getComponent("gmap").getGoogleMap();
            const scale = 1 << map.getZoom();
            const projection = map.getProjection();
            const receivedPoint = projection.fromLatLngToPoint(LatLngLiteral);

            // const NorthEastLat = map.getBounds().getNorthEast().lat();
            // const SouthWestLng = map.getBounds().getSouthWest().lng();
            // const boundaryPoint = projection.fromLatLngToPoint(new google.maps.LatLng(NorthEastLat, SouthWestLng));

            // 计算 x 和 y 坐标
            // const res = new google.maps.Point((receivedPoint.x - boundaryPoint.x) * scale, (receivedPoint.y - boundaryPoint.y) * scale);

            // https://stackoverflow.com/questions/3410600/convert-lat-lon-to-pixels-and-back
            // https://developers.google.com/maps/documentation/javascript/examples/map-coordinates?csw=1
            const NorthEast = projection.fromLatLngToPoint(map.getBounds().getNorthEast()); // 东北 (右上角)
            const SouthWest = projection.fromLatLngToPoint(map.getBounds().getSouthWest()); // 西南 (左下角)
            const res = new google.maps.Point((receivedPoint.x - SouthWest.x) * scale, (receivedPoint.y - NorthEast.y) * scale);

            // console.log('当前像素点坐标', res);
            return res;
        },

        getDeviceData() {
            getDevicesDataByAssetIds(this.strAssetIds).then(mapDeviceData => {
                this.deviceKey_to_DeviceData_Map = mapDeviceData
            })
        },

        // 删除当前设备经纬度数据, 但仍保留 geo true 属性
        deleteCurrentAsset(assetId) {
            deleteAssetAttr(assetId, ["longitude", "latitude"]).then(() => {
                this.showMapConfigure = false;
                getAllAssetVo().then((result) => {
                    console.log(result, '最新数据');
                    this.updateMarkDataInMap(result);
                });
                this.$message.success({
                    center: true,
                    message: 'Success'
                });
            }).catch(() => {
                this.$message.error({
                    center: true,
                    message: "Error"
                });
            });
        },
        onSelectAssetName(assetId) {
            this.showRadioSelectAssetName = false; // 不显示选择设备 (已选定)

            const value = this.PixelToLngLat(this.RadioSelectAssetNameLeft - 9 - 30 / 2, this.RadioSelectAssetNameTop + 39);
            this.showDragEndImage = false;
            setAssetAttr(assetId, { geo: "true", longitude: String(value[0]), latitude: String(value[1]) }).then(() => {
                getAllAssetVo().then((result) => {
                    console.log(result, '最新数据');
                    this.showDragEndImage = false; // 不显示终点图片
                    this.updateMarkDataInMap(result);
                });
                this.$message.success({
                    center: true,
                    message: 'Success'
                });
            }).catch(() => {
                this.$message.error({
                    center: true,
                    message: "Error"
                });
            });
        },
        // 更新地图上标记数据 (只能新增, 不可修改)
        updateMarkDataInMap(data) {
            const EChartsData = []; // ECharts 数据集
            const allAssetsWithGeo = []; // 有地理信息且有经纬度数据的设备列表
            const allAssetsWithNoGeo = []; // 有地理信息但无经纬度数据的设备列表
            data.forEach((item) => {
                // geo 为 true 且有经纬度数据
                if (item.attr.geo === 'true' && item.attr.longitude && item.attr.latitude) {
                    EChartsData.push({ assetId: item.assetId, assetName: item.name, value: [Number(item.attr.longitude), Number(item.attr.latitude)] });
                    allAssetsWithGeo.push({ name: item.name, LatLng: { lng: Number(item.attr.longitude), lat: Number(item.attr.latitude) } });
                }
                // geo 为 true 且无经纬度数据
                if (item.attr.geo === 'true' && !item.attr.longitude && !item.attr.latitude) {
                    allAssetsWithNoGeo.push({ name: item.name, assetId: item.assetId });
                }

                // if (item.attr.geo === 'true') {
                //     EChartsData.push({ assetId: item.assetId, value: [Number(item.attr.longitude), Number(item.attr.latitude)] });
                //     allAssetsWithGeo.push({ name: item.name, LatLng: { lng: Number(item.attr.longitude), lat: Number(item.attr.latitude) } });
                // }
                // else {
                //     // 只可选择无地理信息的设备名称
                //     allAssetsWithNoGeo.push({ name: item.name, assetId: item.assetId });
                // }
            });
            // 更新有地理信息且有经纬度数据的设备列表
            this.allAssetsWithGeo = allAssetsWithGeo;
            // 更新有地理信息但无经纬度数据的设备列表
            this.allAssetsWithNoGeo = allAssetsWithNoGeo;
            const currentEChartsOption = this.EChartsInstance.getOption(); // 使用当前配置选项
            currentEChartsOption.series[0].data = EChartsData; // 更新当前配置数据
            this.EChartsInstance.setOption(currentEChartsOption);
        },
        // 拖拽开始
        onDragStart() {
            return false;
        },
        // 按下鼠标
        onMouseDown() {
            this.showMapConfigure = false; // 不显示设备配置

            const clonedImage = this.$refs.draggableImage.cloneNode(true); // 深克隆节点
            this.$refs.draggableImage.style.visibility = 'hidden'; // 隐藏原节点

            document.body.append(clonedImage);
            // 现在球的中心在 (pageX, pageY) 坐标上
            function moveAt(pageX, pageY) {
                clonedImage.style.left = pageX - clonedImage.offsetWidth / 2 + 'px';
                clonedImage.style.top = pageY - clonedImage.offsetHeight / 2 + 'px';
            }
            // 将我们绝对定位的球移到指针下方
            // moveAt(event.pageX, event.pageY);
            moveAt(event.pageX, event.pageY - 39 / 2);

            function onMouseMove(event) {
                // moveAt(event.pageX, event.pageY);
                moveAt(event.pageX, event.pageY - 39 / 2);
            }

            // (2) 在 mousemove 事件上移动
            // document.addEventListener('mousemove', onMouseMove);
            document.getElementById('echarts-google-map').addEventListener('mousemove', onMouseMove);

            // (3) 放下鼠标，并移除不需要的处理程序
            clonedImage.onmouseup = (e) => {
                clonedImage.remove(); // 移除当前克隆节点

                // 落点的经度和纬度坐标
                // const value = this.PixelToLngLat(e.pageX, e.pageY - 56 - 66); // 
                // this.EChartsInstance.appendData({ seriesIndex: 0, data: [value] }); // https://echarts.apache.org/zh/api.html#echartsInstance.appendData

                this.RadioSelectAssetNameLeft = e.pageX + 9 + 30 / 2;
                const tabBar = document.querySelector(".second-bar-inside-box");
                const tabBarHeight = tabBar.offsetHeight; // 56 or 112
                // this.RadioSelectAssetNameTop = e.pageY - 39 - 56 - 66;
                this.RadioSelectAssetNameTop = e.pageY - 39 - tabBarHeight - 66;
                this.showRadioSelectAssetName = true;
                this.$refs.draggableImage.style.visibility = 'visible'; // 显示原节点

                this.$refs.dragEndImage.style.left = e.pageX - 30 / 2 + 'px';
                // this.$refs.dragEndImage.style.top = e.pageY - 39 - 56 - 66 + 'px';
                this.$refs.dragEndImage.style.top = e.pageY - 39 - tabBarHeight - 66 + 'px';
                this.showDragEndImage = true;

                // const img = document.createElement('img');
                // // img.src = '../assets/Map-Tab/map-mark.png'; // 误
                // // img.setAttribute('src','../assets/Map-Tab/map-mark.png'); // 误
                // // img.src = require('../assets/Map-Tab/map-mark.png'); // 正
                // img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAnCAYAAAAYRfjHAAAAAXNSR0IArs4c6QAAA9ZJREFUWEfNmF1oHFUUx39n7iS2VZpaK21f/GjAgGJ98KNYKrVqdkPXKMS0oODHiwVFqQoWrSiCWKWCWtSC1Qc/QCHGgMaVzEZtq6XQqg+tKERo/XhpxbRNirY1OzNHZvLhTrO7c3ezAedt9/7P/zf3zrnnnhnB4tIemoMFdIhyi8I1QKtASxSqMAocEvhOhS/MCAOynrE0W6km0AEWhoZHNeRBYGGa2cT4cXHY7gS8Ih0crxRTEayD3BUo21AWWQKTMmHYCBulnQ/KxU8Daw/Gn89WER6rC3hWkCovuyfZJOsJSocS4AgazKcP4bZGQKc8lE/NSbpK4QlwscCromxsKHTCTIVtTRkemfSeAqvHvQG8MxvQSU8D90mWd6PfMVi/5sLgNEPA+bMJBk6YgDZZy58xOBhkq4Y8PsvQ2F4cXjLtbBLtYW7YwhGdKAizDRcYdUZZKurRHcBHNQD3qvKcew57ohj/H1aJ8DSw0tbDwDoJPN5U2GAZ1GuauVPW4JfqdSduMMaHQLeNj8AO8QvsR7nWIuCEcbhU2uPaPO3SQVqCkF+sElT4VnyPYeCCVLDwvpvhnmo6v8B7KHenesGxaMZjKE1pYhG2mAxPVdMFBZ5XZXOaF0IxmvEINhndwBmLMBIl1wGF5al3GW3+Bj1jgYPRjN+A+Ly1uRqS1cB2KRbIiOLZUCc00/cxPINwva2HClnRZ3HClRxW5WLbwJnoRPjN2cuy8Vo9wEMqvDYTQ9tYUR42Hbw+fjrtZE44xpDCRbYG9egEfneaaZM1nJk6j30vLne11Ox62OvcLL1RYKID8Qt8hpKrxzE1Rsi7GW6d1CV7rgGWBsIPViU0lZQQHDPKldLBkbLg6M/iIFkJyQOmNu+K6kAdck3tyS1btq8OPJ5U2NIIsMBmk+WFs70qNvS+FyfBHTOEf+xmy5/Rld8kPM4NhC9RVtQFF/YZ5WbJ8ne5+OrvTv0sCpvYrcLltcBF+ckpslo647O+7FUVHBeXPEtCl10KbTZwgSHH50bJcbSaPhUcBf/1CYvnzsFTuCrF7MDpM2TPu50/0m7SChzPfLyn6gNuqmD6lXHoqtSTWWf1hk7mEbJC4DqFy1BajcuS7htoXb0ct9Ro90H83m84FPgcReKX9J8V9uOwb0c/p6yS64EcV/vwhMBaYF65oCsuga5V4yN9e+DHXysu7CmFzxVefDvP96WqxFLfn4sOaPqR9OYv7RkmxpWiCp1v5f+rXv8PcHSXNktdw2ztlrrUMJFcGu/hZQiLIf4mEj375gl99IUnSqBhNN5Gh0XipqJqcv0LyH5YCMb0uiQAAAAASUVORK5CYII=";
                // img.style.width = '30px';
                // img.style.height = '39px';
                // img.style.position = 'fixed';
                // // 须减去宽度和高度的一半, 以使得精准落点
                // // img.style.left = e.screenX - 30 / 2 + 'px'; // 减去 宽度的一半
                // img.style.left = e.pageX - 30 / 2 + 'px'; // 减去 宽度的一半
                // // img.style.top = e.screenY - 56 - 66 - 39 / 2 + 'px'; // 减去高度的一半
                // img.style.top = e.pageY - 56 - 66 - 39 / 2 + 'px'; // 减去高度的一半
                // img.style.zIndex = '9999999999';
                // document.body.append(img);
            };
        },
        searchChange(e) {
            this.showFilteredAssetName = true;
            this.filteredAssetList = this.allAssetsWithGeo.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
            console.log('search', e.target.value, 'all assets', this.allAssetsWithGeo, 'filtered assets', this.filteredAssetList);
        },
        updateSearch(asset) {
            // this.searchText = asset.name;
            console.log('asset LatLng', asset.LatLng);
            this.updateCenter(false, [asset.LatLng.lng, asset.LatLng.lat], true);
        },
        updateCenter(bool, center = this.longitudeAndLatitudeMap[this.selectedSite], isZoomIn = false) {
            this.showMapConfigure = false;
            this.showDragEndImage = false;
            this.showRadioSelectAssetName = false;
            // https://element.eleme.cn/#/zh-CN/component/select#select-events
            // 下拉框隐藏时, 回调参数为 false
            if (!bool) {
                // 更新地图中心和放大比例
                if (isZoomIn) {
                    this.getMaxZoomAtLatLng(center);
                } else {
                    this.EChartsInstance.setOption({ gmap: { center: center } });
                }
            }
        },
        // https://developers.google.cn/maps/documentation/javascript/maxzoom
        getMaxZoomAtLatLng(center) {
            const LatLng = { lng: center[0], lat: center[1] };
            const maxZoomService = new google.maps.MaxZoomService();
            maxZoomService.getMaxZoomAtLatLng(
                LatLng,
                (result) => {
                    if (result.status == "OK") {
                        console.log("最大放大比例", result.zoom);
                        // 最大放大比例的 80%
                        this.EChartsInstance.setOption({ gmap: { center: center, zoom: 0.8 * result.zoom } });
                    }
                    else {
                        // 请求失败, 放大比例设为 15
                        this.EChartsInstance.setOption({ gmap: { center: center, zoom: 15 } });
                    }
                }
            );
        },
        initGoogleMap() {
            // https://github.com/plainheart/echarts-extension-gmap/blob/master/examples/scatter_zh_CN.html#L433
            const option = {
                // backgroundColor: "rgba(33, 42, 58, 0.5)",
                // 加载 google map 组件
                gmap: {
                    // 地图中心 支持数组或 JSON 对象
                    // center: [200, 60],
                    // center: { lng: 104.137435, lat: 35.780287 },
                    // 缩放级别
                    zoom: 5,
                    // 其他地图初始化参数: https://developers.google.cn/maps/documentation/javascript/reference/map#MapOptions
                    gestureHandling: "greddy", // 谷歌地图平移和缩放事件: https://developers.google.cn/maps/documentation/javascript/reference/map#MapOptions.gestureHandling
                    // 移动过程中实时渲染。默认为 true。如数据量较大，建议置为 false。
                    renderOnMoving: true,
                    // 谷歌地图 ECharts 图层的 zIndex。默认为 2000。
                    echartsLayerZIndex: 2019,
                    // 是否启用手势事件处理，如拖拽等。默认为 true。
                    // 此配置项从 v1.4.0 起支持
                    // roam: false,

                    disableDefaultUI: true,
                    // 启用小数缩放级别
                    // isFractionalZoomEnabled: true, // https://developers.google.cn/maps/documentation/javascript/reference/map#MapOptions.isFractionalZoomEnabled
                    // 设置谷歌地图最小缩放级别
                    minZoom: 3, // // https://developers.google.cn/maps/documentation/javascript/reference/map#MapOptions.minZoom

                    styles: [
                        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                        {
                            featureType: "administrative.locality",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#d59563" }],
                        },
                        {
                            featureType: "poi",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#d59563" }],
                        },
                        {
                            featureType: "poi.park",
                            elementType: "geometry",
                            stylers: [{ color: "#263c3f" }],
                        },
                        {
                            featureType: "poi.park",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#6b9a76" }],
                        },
                        {
                            featureType: "road",
                            elementType: "geometry",
                            stylers: [{ color: "#38414e" }],
                        },
                        {
                            featureType: "road",
                            elementType: "geometry.stroke",
                            stylers: [{ color: "#212a37" }],
                        },
                        {
                            featureType: "road",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#9ca5b3" }],
                        },
                        // {
                        //     featureType: "road.highway",
                        //     elementType: "geometry",
                        //     stylers: [{ color: "#746855" }],
                        // },
                        // {
                        //     featureType: "road.highway",
                        //     elementType: "geometry.stroke",
                        //     stylers: [{ color: "#1f2835" }],
                        // },
                        // {
                        //     featureType: "road.highway",
                        //     elementType: "labels.text.fill",
                        //     stylers: [{ color: "#f3d19c" }],
                        // },
                        {
                            featureType: "transit",
                            elementType: "geometry",
                            stylers: [{ color: "#2f3948" }],
                        },
                        {
                            featureType: "transit.station",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#d59563" }],
                        },
                        {
                            featureType: "water",
                            elementType: "geometry",
                            stylers: [{ color: "#17263c" }],
                        },
                        {
                            featureType: "water",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#515c6d" }],
                        },
                        {
                            featureType: "water",
                            elementType: "labels.text.stroke",
                            stylers: [{ color: "#17263c" }],
                        },

                        // {
                        //     "featureType": "administrative.*",
                        //     "elementType": "geometry.stroke",
                        //     "stylers": [
                        //     {
                        //         "color": "#ffffff"
                        //     }
                        //     ]
                        // },

                        {
                            "featureType": "administrative.country",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },

                        {
                            "featureType": "administrative.province",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },

                        // {
                        //     "featureType": "administrative.city",
                        //     "elementType": "geometry.stroke",
                        //     "stylers": [
                        //     {
                        //         "color": "#ffffff"
                        //     }
                        //     ]
                        // },
                    ],
                },
                animation: false, // 禁用动画效果
                series: [
                    {
                        name: 'PM2.5',
                        type: 'scatter',
                        // 使用谷歌地图坐标系
                        coordinateSystem: 'gmap',
                        // 数据格式跟在 geo 坐标系上一样，每一项都是 [经度，纬度，数值大小，其它维度...]
                        data: this.markedDataInMap,
                        // https://echarts.apache.org/zh/option.html#series-scatter.symbol
                        // 右上角试一试 上传图片转为 dataURI
                        symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAnCAYAAAAYRfjHAAAAAXNSR0IArs4c6QAAA9ZJREFUWEfNmF1oHFUUx39n7iS2VZpaK21f/GjAgGJ98KNYKrVqdkPXKMS0oODHiwVFqQoWrSiCWKWCWtSC1Qc/QCHGgMaVzEZtq6XQqg+tKERo/XhpxbRNirY1OzNHZvLhTrO7c3ezAedt9/7P/zf3zrnnnhnB4tIemoMFdIhyi8I1QKtASxSqMAocEvhOhS/MCAOynrE0W6km0AEWhoZHNeRBYGGa2cT4cXHY7gS8Ih0crxRTEayD3BUo21AWWQKTMmHYCBulnQ/KxU8Daw/Gn89WER6rC3hWkCovuyfZJOsJSocS4AgazKcP4bZGQKc8lE/NSbpK4QlwscCromxsKHTCTIVtTRkemfSeAqvHvQG8MxvQSU8D90mWd6PfMVi/5sLgNEPA+bMJBk6YgDZZy58xOBhkq4Y8PsvQ2F4cXjLtbBLtYW7YwhGdKAizDRcYdUZZKurRHcBHNQD3qvKcew57ohj/H1aJ8DSw0tbDwDoJPN5U2GAZ1GuauVPW4JfqdSduMMaHQLeNj8AO8QvsR7nWIuCEcbhU2uPaPO3SQVqCkF+sElT4VnyPYeCCVLDwvpvhnmo6v8B7KHenesGxaMZjKE1pYhG2mAxPVdMFBZ5XZXOaF0IxmvEINhndwBmLMBIl1wGF5al3GW3+Bj1jgYPRjN+A+Ly1uRqS1cB2KRbIiOLZUCc00/cxPINwva2HClnRZ3HClRxW5WLbwJnoRPjN2cuy8Vo9wEMqvDYTQ9tYUR42Hbw+fjrtZE44xpDCRbYG9egEfneaaZM1nJk6j30vLne11Ox62OvcLL1RYKID8Qt8hpKrxzE1Rsi7GW6d1CV7rgGWBsIPViU0lZQQHDPKldLBkbLg6M/iIFkJyQOmNu+K6kAdck3tyS1btq8OPJ5U2NIIsMBmk+WFs70qNvS+FyfBHTOEf+xmy5/Rld8kPM4NhC9RVtQFF/YZ5WbJ8ne5+OrvTv0sCpvYrcLltcBF+ckpslo647O+7FUVHBeXPEtCl10KbTZwgSHH50bJcbSaPhUcBf/1CYvnzsFTuCrF7MDpM2TPu50/0m7SChzPfLyn6gNuqmD6lXHoqtSTWWf1hk7mEbJC4DqFy1BajcuS7htoXb0ct9Ro90H83m84FPgcReKX9J8V9uOwb0c/p6yS64EcV/vwhMBaYF65oCsuga5V4yN9e+DHXysu7CmFzxVefDvP96WqxFLfn4sOaPqR9OYv7RkmxpWiCp1v5f+rXv8PcHSXNktdw2ztlrrUMJFcGu/hZQiLIf4mEj375gl99IUnSqBhNN5Gh0XipqJqcv0LyH5YCMb0uiQAAAAASUVORK5CYII=",
                        symbolSize: [30, 39],
                        symbolOffset: [0, '-50%'], // https://echarts.apache.org/zh/option.html#series-scatter.symbolOffset
                        encode: {
                            value: 2,
                            lng: 0,
                            lat: 1
                        },
                        label: {
                            formatter: '{b}',
                            position: 'right',
                            show: true,
                            // color: 'red'
                        },
                        labelLayout: {
                            hideOverlap: true,
                        },
                        itemStyle: {
                            color: '#00c1de'
                        },
                        emphasis: {
                            label: {
                                show: true
                            }
                        },
                    },
                    // {
                    //     name: 'Top 5',
                    //     type: 'effectScatter',
                    //     coordinateSystem: 'gmap',
                    //     // data: convertData(data.sort(function (a, b) {
                    //     //     return b.value - a.value;
                    //     // }).slice(0, 6)),
                    //     data: this.markedDataInMap,
                    //     symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAnCAYAAAAYRfjHAAAAAXNSR0IArs4c6QAAA9ZJREFUWEfNmF1oHFUUx39n7iS2VZpaK21f/GjAgGJ98KNYKrVqdkPXKMS0oODHiwVFqQoWrSiCWKWCWtSC1Qc/QCHGgMaVzEZtq6XQqg+tKERo/XhpxbRNirY1OzNHZvLhTrO7c3ezAedt9/7P/zf3zrnnnhnB4tIemoMFdIhyi8I1QKtASxSqMAocEvhOhS/MCAOynrE0W6km0AEWhoZHNeRBYGGa2cT4cXHY7gS8Ih0crxRTEayD3BUo21AWWQKTMmHYCBulnQ/KxU8Daw/Gn89WER6rC3hWkCovuyfZJOsJSocS4AgazKcP4bZGQKc8lE/NSbpK4QlwscCromxsKHTCTIVtTRkemfSeAqvHvQG8MxvQSU8D90mWd6PfMVi/5sLgNEPA+bMJBk6YgDZZy58xOBhkq4Y8PsvQ2F4cXjLtbBLtYW7YwhGdKAizDRcYdUZZKurRHcBHNQD3qvKcew57ohj/H1aJ8DSw0tbDwDoJPN5U2GAZ1GuauVPW4JfqdSduMMaHQLeNj8AO8QvsR7nWIuCEcbhU2uPaPO3SQVqCkF+sElT4VnyPYeCCVLDwvpvhnmo6v8B7KHenesGxaMZjKE1pYhG2mAxPVdMFBZ5XZXOaF0IxmvEINhndwBmLMBIl1wGF5al3GW3+Bj1jgYPRjN+A+Ly1uRqS1cB2KRbIiOLZUCc00/cxPINwva2HClnRZ3HClRxW5WLbwJnoRPjN2cuy8Vo9wEMqvDYTQ9tYUR42Hbw+fjrtZE44xpDCRbYG9egEfneaaZM1nJk6j30vLne11Ox62OvcLL1RYKID8Qt8hpKrxzE1Rsi7GW6d1CV7rgGWBsIPViU0lZQQHDPKldLBkbLg6M/iIFkJyQOmNu+K6kAdck3tyS1btq8OPJ5U2NIIsMBmk+WFs70qNvS+FyfBHTOEf+xmy5/Rld8kPM4NhC9RVtQFF/YZ5WbJ8ne5+OrvTv0sCpvYrcLltcBF+ckpslo647O+7FUVHBeXPEtCl10KbTZwgSHH50bJcbSaPhUcBf/1CYvnzsFTuCrF7MDpM2TPu50/0m7SChzPfLyn6gNuqmD6lXHoqtSTWWf1hk7mEbJC4DqFy1BajcuS7htoXb0ct9Ro90H83m84FPgcReKX9J8V9uOwb0c/p6yS64EcV/vwhMBaYF65oCsuga5V4yN9e+DHXysu7CmFzxVefDvP96WqxFLfn4sOaPqR9OYv7RkmxpWiCp1v5f+rXv8PcHSXNktdw2ztlrrUMJFcGu/hZQiLIf4mEj375gl99IUnSqBhNN5Gh0XipqJqcv0LyH5YCMb0uiQAAAAASUVORK5CYII=",
                    //     // symbolSize: function (arr) {
                    //     //     // return arr[2] / 10;
                    //     //     return 50;
                    //     // },
                    //     symbolSize: [30, 39],
                    //     encode: {
                    //         value: 2,
                    //         lng: 0,
                    //         lat: 1
                    //     },
                    //     showEffectOn: 'render',
                    //     rippleEffect: {
                    //         brushType: 'stroke'
                    //     },
                    //     label: {
                    //         formatter: '{b}',
                    //         position: 'right',
                    //         show: true
                    //     },
                    //     itemStyle: {
                    //         color: '#fff',
                    //         shadowBlur: 10,
                    //         shadowColor: '#333'
                    //     },
                    //     zlevel: 1,
                    // }
                ]
            };
            // 初始化ECharts
            const chart = echarts.init(document.getElementById("echarts-google-map"));
            chart.setOption(option);
            this.EChartsInstance = chart; // 保存 ECharts 实例

            // 给整个画布添加点击事件
            // chart.getZr().on('click', (e) => {
            // this.showMapConfigure = false;
            // if (e.target !== undefined) {
            //     console.log("当前点击坐标", e, e.offsetX, e.offsetY);
            //     this.left = e.offsetX; // TODO 间距
            //     this.top = e.offsetY + 66 + 56; // 顶部 div.top-bar-inside-box (66px) + div.second-bar (56px)
            //     this.showMapConfigure = true;
            // }
            // });

            chart.on('click', (e) => {
                // 减少重复点击带来的重复计算开销
                console.log(e);
                if (e.dataIndex !== this.previousMapMarkDataIndex || !this.showMapConfigure) {
                    if (!this.showRadioSelectAssetName) {
                        // console.log('clicked');
                        // 经度和纬度: e.value 或 chart.getOption().series[0].data[e.dataIndex].value
                        const point = this.LngLatToPixel(e.value);
                        this.MapConfigureLeft = point.x + 30 / 2 + 10; // 自身宽度一半 (30/2) + 间距 10px
                        this.MapConfigureTop = point.y - 39; // - 自身高度 39px
                        this.showMapConfigure = true;
                        this.MapConfigureStaticData = {
                            name: e.data.assetName,
                            assetId: e.data.assetId,
                            nodeIp: e.data.nodeIp,
                            url: e.data.url,
                            deviceData: this.deviceKey_to_DeviceData_Map[e.data.deviceKey],
                        };
                    }
                    // console.log('clicked');
                    // // 经度和纬度: e.value 或 chart.getOption().series[0].data[e.dataIndex].value
                    // const point = this.LngLatToPixel(e.value);
                    // this.MapConfigureLeft = point.x + 30 / 2 + 10; // 自身宽度一半 (30/2) + 间距 10px
                    // this.MapConfigureTop = point.y + 66 + 56 - 39; // 顶部 div.top-bar-inside-box (66px) + div.second-bar (56px) - 自身高度 39px
                    // this.showMapConfigure = true;
                }
                // 更新索引
                this.previousMapMarkDataIndex = e.dataIndex;
            });

            // 鼠标拖拽事件
            // https://echarts.apache.org/zh/api.html#events.%E9%BC%A0%E6%A0%87%E4%BA%8B%E4%BB%B6
            chart.getZr().on('mouseup', (e) => {
                // this.LngLatToPixel([-87.65, 41.85]);

                this.showDragEndImage = false;
                // this.showMapConfigure = false;
                // this.showRadioSelectAssetName = false;
                if (e.target === undefined && this.showMapConfigure) {
                    this.showMapConfigure = false;
                }
                if (e.target === undefined && this.showRadioSelectAssetName) {
                    this.showRadioSelectAssetName = false;
                }
            });

            // 鼠标滚动事件: https://developer.mozilla.org/en-US/docs/Web/API/Element/wheel_event#Listening_to_this_event_across_browser
            // https://stackoverflow.com/questions/16788995/mousewheel-event-is-not-triggering-in-firefox-browser
            // wheel / mousewheel / DOMMouseScroll
            document.getElementById("echarts-google-map").addEventListener('wheel', () => {
                const GoogleMapInstance = this.EChartsInstance.getModel().getComponent("gmap").getGoogleMap();
                const overlay = new google.maps.OverlayView();
                overlay.draw = () => { };
                // overlay.onAdd = () => { };
                // overlay.onRemove = () => { };
                overlay.setMap(GoogleMapInstance);
                // const NorthEast = GoogleMapInstance.getBounds().getNorthEast();
                // console.log(overlay.getProjection().fromLatLngToDivPixel(NorthEast), 999);
                // console.log(overlay.getProjection().getWorldWidth(), 999);
                // console.log(GoogleMapInstance.MapCanvasProjection(), 999);

                // const NorthEast = GoogleMapInstance.getBounds().getNorthEast();
                // const SouthWest = GoogleMapInstance.getBounds().getSouthWest();
                // const NorthEastPixel = this.LngLatToPixel([NorthEast.lng(), NorthEast.lat()]);
                // const SouthWestPixel = this.LngLatToPixel([SouthWest.lng(), SouthWest.lat()]);
                // const deltaX = NorthEastPixel.x - SouthWestPixel.x;
                // const deltaY = SouthWestPixel.y - NorthEastPixel.y;
                // console.log(deltaX, deltaY);
                // console.log(GoogleMapInstance.getZoom(), [NorthEast.lng(), NorthEast.lat()], [SouthWest.lng(), SouthWest.lat()]);

                // this.EChartsInstance.appendData({ seriesIndex: 0, data: [[NorthEast.lng(), NorthEast.lat()], [SouthWest.lng(), SouthWest.lat()]] });

                // console.log(GoogleMapInstance.getBounds(), GoogleMapInstance.getZoom(), NorthEastPixel, SouthWestPixel, 99999);

                this.showDragEndImage = false;
                this.showMapConfigure = false;
                this.showRadioSelectAssetName = false;
                // if (this.showMapConfigure) {
                //     this.showMapConfigure = false;
                // }
                // if (this.showRadioSelectAssetName) {
                //     this.showRadioSelectAssetName = false;
                // }
            });

            // console.log('getModel', chart.getModel())
            // console.log('gmap', chart.getModel().getComponent("gmap"))
            // 获取谷歌地图实例
            // const gmap = chart.getModel().getComponent("gmap").getGoogleMap();
            // 添加一个Marker
            // const marker = new google.maps.Marker({ position: gmap.getCenter() });
            // marker.setMap(gmap);
            // 添加交通图层
            // const trafficLayer = new google.maps.TrafficLayer();
            // trafficLayer.setMap(gmap);
        },

        getDevicesData() {
            console.log('getDeviceDataInterval start')
            getDevicesDataByAssetIds(this.strAssetIds).then(mapDeviceData => {
                this.deviceKey_to_DeviceData_Map = mapDeviceData
            })
        }
    },
    async mounted() {
        const scripts = [
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyDRbkctbJzDKxC-49pMvvwmjW4bGV4-WNI&region=CH&language=en',
            'https://cdn.jsdelivr.net/npm/echarts@5/dist/echarts.min.js',
            'https://cdn.jsdelivr.net/npm/echarts-extension-gmap@1.4.0/dist/echarts-extension-gmap.min.js',
        ];

        await this.loadScripts(scripts);
        console.log('scripts loaded');

        getAllAssetVo().then((result) => {
            result.map((item) => {
                // geo 为 true 且有经纬度数据
                if (item.attr.geo === 'true' && item.attr.longitude && item.attr.latitude) {
                    this.markedDataInMap.push({
                        assetId: item.assetId,
                        assetName: item.name,
                        deviceKey: item.deviceKey,
                        nodeIp: item.deviceInfo ? item.deviceInfo.node_ip :'---.---.---.---',
                        url: item.deviceInfo && item.deviceInfo.hls ? item.deviceInfo.hls[0] : "",
                        value: [Number(item.attr.longitude), Number(item.attr.latitude)],
                        name: item.name,
                    });
                    this.allAssetsWithGeo.push({ name: item.name, LatLng: { lng: Number(item.attr.longitude), lat: Number(item.attr.latitude) } });

                    this.strAssetIds = item.assetId + ','
                }
                // geo 为 true 且无经纬度数据
                if (item.attr.geo === 'true' && !item.attr.longitude && !item.attr.latitude) {
                    this.allAssetsWithNoGeo.push({ name: item.name, assetId: item.assetId });
                }

                // if (item.attr.geo === 'true') {
                //     this.markedDataInMap.push({ assetId: item.assetId, value: [Number(item.attr.longitude), Number(item.attr.latitude)] });
                //     this.allAssetsWithGeo.push({ name: item.name, LatLng: { lng: Number(item.attr.longitude), lat: Number(item.attr.latitude) } });
                // }
                // else {
                //     // 只可选择无地理信息的设备名称
                //     this.allAssetsWithNoGeo.push({ name: item.name, assetId: item.assetId });
                // }
            });

            //TODO
            // this.strAssetIds = '105'
            if (this.strAssetIds !== '') {
                this.getDevicesData()
                // this.getDeviceDataIntervalId = setInterval(this.getDevicesData, 10000);
            }

            this.initGoogleMap();
        });
    },

    beforeDestroy: function () {
        // if(this.getDeviceDataIntervalId !== null) {
        //     console.log('getDeviceDataInterval stop')
        //     clearInterval(this.getDeviceDataIntervalId);
        // }
    }
};
</script>

<style>
.map-custom-select {
    margin-top: 0 !important;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    background: #1B2536;
}

.map-custom-select ul {
    padding: 0;
}

.map-custom-select .el-select-dropdown__wrap {
    width: 248px;
    max-height: 394px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}

.map-custom-select .el-scrollbar__bar {
    display: none;
}

.map-custom-select .popper__arrow {
    display: none !important;
}
</style>

<style scoped>
/* 不显示右下角 */
::v-deep div.gmnoprint {
    display: none;
}

.el-tooltip__popper>>>.popper__arrow {
    border-top-color: #303133;
    border-bottom-color: #303133;
}

.el-tooltip__popper>>>.popper__arrow::after {
    border-top-color: #303133;
    border-bottom-color: #303133;
}

.container {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 100%;
}

img.top-right-fixed-img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 120px;
    cursor: pointer;
    user-select: none;
    /* transition: visibility 0.3s ease-in; */
}

img.drag-end-img {
    width: 30px;
    height: 39px;
    /* position: fixed; */
    position: absolute;
}

#echarts-google-map {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

input:focus {
    outline: 1px solid #333B47;
}

.search-input-form {
    position: absolute;
    top: 20px;
    left: 56px;
    border: none;
    z-index: 2;
    padding-left: 50px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 250px;
    height: 48px;
    font-family: Helvetica;
    color: #FFFFFF;
    background: #13191B url('../assets/dashboard-search-magnifier.png') no-repeat;
    background-position-x: 12px;
    background-position-y: 12px;
}

.select-box {
    position: absolute;
    top: 20px;
    left: 382px;
    z-index: 2;
    width: 134px;
    height: 48px;
}

div.map-custom-select li.el-select-dropdown__item {
    color: #ffffff;
    background-color: #101517;
    height: initial;
    line-height: initial;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
}

div.map-custom-select li.el-select-dropdown__item.selected {
    font-weight: 400;
}

::v-deep .el-select .el-input__inner {
    height: 48px;
    cursor: pointer;
    padding-right: 35px;
    background: #13191B;
    font-size: 14px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
    border-radius: 0;
}

::v-deep .el-select .el-select__caret.el-input__icon.el-icon-arrow-up {
    color: #BFC3CB;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #13191B;
}

::v-deep .el-select .el-input__inner {
    border-color: #13191B;
}
</style>