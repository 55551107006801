export default {
  methods: {
    loadScripts(scripts) {
      // Array to keep track of all script elements
      this.scriptElements = [];

      // Load scripts sequentially to ensure correct order
      return scripts.reduce((promise, src) => {
        return promise.then(() => this.loadScript(src));
      }, Promise.resolve());
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        // Check if the script already exists
        if (document.querySelector(`script[src="${src}"]`)) {
          console.log(`Script already loaded: ${src}`);
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = src;
        script.async = true; // Add async attribute
        script.onload = () => resolve();
        script.onerror = () => {
          console.error(`Failed to load script ${src}`);
          this.removeAllScripts(); // Remove all script nodes on error
          reject(new Error(`Failed to load script ${src}`));
        };
        document.head.appendChild(script);
        this.scriptElements.push(script); // Keep track of the script element
      });
    },
    removeAllScripts() {
      this.scriptElements.forEach(script => script.remove());
      this.scriptElements = []; // Clear the array
    }
  }
};